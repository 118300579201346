import {
  apiGetGameCat,
  // apiGetGameType,
  apiGetGameMenu,
  apiGetDetail,
  apiGetSearch,
  apiGetDetailSmall,
  apiGetGameResult,
  apiGetGameLeague,
  apiGetAnnouncement,
  apiGetGameLimit,
  apiGetNews,
  apiGetLive,
  apiGetMovie,
  apiGetLiveEvents,
} from '@/api/game';
import {
  apiGetCServiceMessages,
  apiGetCServiceWelcome,
  apiGetCServiceUnread,
  apiCServicePostRead,
  apiPostCServiceMessage,
  apiPostCServiceFile,
} from '@/api/customer-service';
import i18n from '@/i18n';
import rootStore from '@/store';
import { getMenuIconByCatID, getBoardImageByCatId, getColorByCatId } from '@/utils/SportLib';
import { GAME_FAVORITE_CAT, GAME_HOT_CAT, GAME_CHAMPION_CAT } from '@/config';

import { triggerSocket, isSocketOk } from '@/utils/oddsSocket';
import { openNewWindow } from '@/config/casino';
import router from '@/router';

const fixHeadName = (name, locale) => {
  switch (locale) {
    case 'tw':
      return name.replace('全場', '上半');
    case 'cn':
      return name.replace('全场', '上半');
    case 'en':
      return name.replace('Full Time', '1st Half');
    case 'vi':
      return name.replace('Sân đấu', 'Nửa đầu trận');
  }
  return '';
};

export default {
  namespaced: true,
  state: {
    GameTypeList: [],
    CatList: [],
    CatMapData: {},
    OddsAdj: {},
    FullMenuList: [],
    GameList: [],
    selectGameType: null,
    selectCatID: null,
    selectWagerTypeKey: null,
    selectLeagueIDs: [],
    selectLeagueIDs2: [],
    isCallGameDetailAPI: false,
    boldFilterType: true,
    isQuickBet: {
      isEnable: false,
      amount: 100,
    },
    isShowService: false,
    readAnnouncements: null,
    announcements: [],
    detailTimeoutID: null,
    isShowGameBet: false,
    limits: null,
    teamImages: [],
    Default: null,
    first: true,
    firstCats: [],
    lives: [],
    livesShow: false,
    searchMode: false,
    searchKey: '',
    searchEvents: [],
  },
  getters: {
    selectGameTypeName(state) {
      const { GameTypeList, selectGameType } = state;
      if (GameTypeList.length === 0 || selectGameType === null) return '';
      const typeItem = GameTypeList.find((item) => item.key === selectGameType);
      return typeItem === null ? '' : typeItem.value;
    },
    selectWagerTypeName(state) {
      const { selectGameType, FullMenuList } = state;
      if (selectGameType === null || FullMenuList.length === 0) return '';
      const menuItem = FullMenuList.find((item) => item.GameType === selectGameType);
      const selectMenuCatList = !menuItem ? [] : menuItem.LeftMenu.item;
      const { selectCatID, selectWagerTypeKey } = state;
      if (selectMenuCatList.length === 0 || !selectCatID || !selectWagerTypeKey) return '';
      const catItem = selectMenuCatList.find((item) => item.catid === selectCatID);
      if (catItem === null) return '';
      const wagerItem = catItem.Items.find((item) => item.WagerTypeKey === selectWagerTypeKey);
      return !wagerItem ? '' : wagerItem.WagerTypeName;
    },
    selectMenuCatList(state) {
      const { selectGameType, FullMenuList } = state;
      if (selectGameType === null || FullMenuList.length === 0) return [];
      const menuItem = FullMenuList.find((item) => item.GameType === selectGameType);
      return !menuItem ? [] : menuItem.LeftMenu.item;
    },
    LeagueList(state) {
      const { GameList, selectLeagueIDs } = state;
      if (GameList.length) {
        const leagues = [];
        GameList[0].Items.List.forEach((league) => {
          const { LeagueID, LeagueNameStr: LeagueName } = league;
          const index = leagues.findIndex(({ LeagueID }) => LeagueID === league.LeagueID);
          if (index === -1) {
            leagues.push({ LeagueID, LeagueName, isSelect: false });
          }
        });
        leagues.forEach((league) => {
          const index = selectLeagueIDs.indexOf(league.LeagueID);
          if (index !== -1) {
            league.isSelect = true;
          }
        });
        return leagues;
      } else {
        return [];
      }
    },
    GameListFilterBySelectLeague(state) {
      if (state.GameList.length !== 0) {
        // 如果沒選擇聯盟
        if (
          state.selectCatID === GAME_FAVORITE_CAT ||
          state.selectCatID === GAME_HOT_CAT ||
          state.selectLeagueIDs.length === 0
        ) {
          return state.GameList;
        } else {
          // 如果有選擇聯盟
          const newGameList = JSON.parse(JSON.stringify(state.GameList));
          newGameList[0].Items.List = newGameList[0].Items.List.filter((it) => {
            return state.selectLeagueIDs.indexOf(it.LeagueID) !== -1;
          });
          return newGameList;
        }
      } else {
        return [];
      }
    },
    hasUnreadAnnouncements(state) {
      const announcements = state.announcements;
      for (const announcement of announcements) {
        if (!announcement.read) return true;
      }
      return false;
    },
  },
  mutations: {
    setShowGameBet(state, val) {
      state.isShowGameBet = val;
    },
    setGameTypeList(state, val) {
      state.GameTypeList = val;
    },
    setCatList(state, val) {
      state.CatList = val;
    },
    setCatMapData(state, val) {
      state.CatMapData = val;
    },
    setFullMenuList(state, val) {
      state.FullMenuList = val;
    },
    setOddsAdj(state, val) {
      state.OddsAdj = val;
    },
    setGameType(state, val) {
      state.selectGameType = val;
    },
    setCatIDAndGameTypeAndWagerType(state, { selectGameType, selectCatID, selectWagerTypeKey }) {
      state.selectGameType = selectGameType;
      state.selectCatID = selectCatID;
      state.selectWagerTypeKey = selectWagerTypeKey;
      state.searchMode = false;
      state.searchKey = '';
    },
    changeCatReset(state, cat) {
      const t = state.selectGameType;
      const c = rootStore.state.Setting.UserSetting.leagues[cat];
      const list = c ? c[t] : null;
      if (list && list.length > 0) {
        state.selectLeagueIDs2 = list;
      } else {
        state.selectLeagueIDs2 = [];
      }
      state.selectLeagueIDs = [];
    },
    setSelectLeagueIDs2(state, val) {
      state.selectLeagueIDs2 = val;
    },
    setSelectLeagueIDs(state, val) {
      state.selectLeagueIDs = val;
    },
    updateSelectedGameList(state) {
      const list = [];
      state.GameList[0].Items.List.forEach((item) => {
        if (state.selectLeagueIDs2.indexOf(item.LeagueID) === -1) {
          list.push(item.LeagueID);
        }
      });
      state.selectLeagueIDs = list;
    },
    setBoldFilterType(state, val) {
      state.boldFilterType = val;
    },
    setIsShowService(state, val) {
      state.isShowService = val;
    },
    setQuickBetEnable(state, val) {
      state.isQuickBet.isEnable = val;
    },
    setQuickBetAmount(state, val) {
      state.isQuickBet.amount = val;
    },
    clearGameList(state) {
      state.GameList = [];
    },
    setGameList(state, data) {
      const { data: gameList, isFavorite, postData } = data;
      const { WagerTypeKey } = postData;
      const temTeamList = [];

      const newGameList = gameList.map((gameData) => {
        const { CatID, CatName, Items } = gameData;
        const { BestHead: headList, List: leagueList } = Items;
        let fix = 0;
        let newHeadList;
        let newHeadList1;
        let newHeadList2;
        let hasMoreCount = false;

        switch (CatID) {
          // 足球
          // 世界盃 冠軍盃
          case 1:
          case 31:
            switch (WagerTypeKey) {
              case 2: // 角球
                fix = 2;
                break;
              case 3: // 波膽
                fix = 3;
                break;
              case 4: // 入球數
                fix = 4;
                break;
              case 5: // 半全場
                fix = 5;
                break;
            }
            break;
          // 籃球
          case 3:
          case 102:
            switch (WagerTypeKey) {
              // 單隊總分
              case 6:
                fix = 6;
                break;
            }
            break;
        }

        switch (fix) {
          case 2:
            newHeadList1 = headList.map((headData) => {
              const WagerGrpIDs = headData.WagerGrpIDs.filter((id) => [0, 10, 20].includes(id));
              return {
                WagerTypeIDs: headData.WagerTypeIDs,
                WagerGrpIDs: WagerGrpIDs,
                WagerGrpName: headData.WagerGrpName,
                showName: headData.Name,
              };
            });
            newHeadList2 = headList.map((headData) => {
              const WagerGrpIDs = headData.WagerGrpIDs.filter((id) => [1, 11, 21].includes(id));
              return {
                WagerTypeIDs: headData.WagerTypeIDs,
                WagerGrpIDs: WagerGrpIDs,
                WagerGrpName: headData.WagerGrpName,
                showName: fixHeadName(headData.Name, i18n.locale),
              };
            });
            newHeadList = [...newHeadList1, ...newHeadList2];
            break;
          case 3:
            newHeadList = [];
            break;
          case 4:
            newHeadList1 = headList
              .filter((headData) => [0, 10, 20].includes(headData.WagerGrpIDs[0]))
              .map((headData) => {
                return {
                  WagerTypeIDs: headData.WagerTypeIDs,
                  WagerGrpIDs: headData.WagerGrpIDs,
                  WagerGrpName: headData.WagerGrpName,
                  OULine: headData.OULine,
                  showName: headData.Name,
                };
              });
            newHeadList2 = headList
              .filter((headData) => [1, 11, 21].includes(headData.WagerGrpIDs[0]))
              .map((headData) => {
                return {
                  WagerTypeIDs: headData.WagerTypeIDs,
                  WagerGrpIDs: headData.WagerGrpIDs,
                  WagerGrpName: headData.WagerGrpName,
                  OULine: headData.OULine,
                  showName: headData.Name,
                };
              });
            newHeadList = [...newHeadList1, ...newHeadList2];
            break;
          case 5:
            newHeadList = headList.map((headData) => {
              return {
                WagerTypeIDs: headData.WagerTypeIDs,
                WagerGrpIDs: headData.WagerGrpIDs,
                WagerGrpName: headData.WagerGrpName,
                OULine: headData.OULine,
                showName: headData.Name,
              };
            });
            break;
          // 籃球 單隊總分
          case 6:
            newHeadList1 = headList.map((headData) => {
              const WagerGrpIDs = headData.WagerGrpIDs.filter((id) => [0, 10, 20].includes(id));
              return {
                WagerTypeIDs: headData.WagerTypeIDs,
                WagerGrpIDs: WagerGrpIDs,
                WagerGrpName: headData.WagerGrpName,
                showName: headData.Name,
              };
            });
            newHeadList2 = headList.map((headData) => {
              const WagerGrpIDs = headData.WagerGrpIDs.filter((id) => [1, 11, 21].includes(id));
              return {
                WagerTypeIDs: headData.WagerTypeIDs,
                WagerGrpIDs: WagerGrpIDs,
                WagerGrpName: headData.WagerGrpName,
                showName: fixHeadName(headData.Name, i18n.locale),
              };
            });
            newHeadList = [...newHeadList1, ...newHeadList2];
            break;
          default:
            newHeadList = headList.map((headData) => {
              return {
                WagerTypeIDs: headData.WagerTypeIDs,
                WagerGrpIDs: headData.WagerGrpIDs,
                WagerGrpName: headData.WagerGrpName,
                showName: headData.Name,
              };
            });
        }

        const newLeagueList = leagueList
          .filter((leagueData) => {
            const { Team: teamList } = leagueData;
            const newTeamList = teamList.filter((teamData) => teamData.EvtStatus === 1);
            return newTeamList.length > 0;
          })
          .map((leagueData, index) => {
            return { ...leagueData, id: index, CatNameStr: CatName };
          })
          .map((leagueData) => {
            const { Team: teamList } = leagueData;
            const newLeagueData = JSON.parse(JSON.stringify(leagueData));
            const newTeamList = teamList.map((teamData) => {
              const { Wager: teamWager } = teamData;
              const newTeamData = JSON.parse(JSON.stringify(teamData));
              const wagerList = JSON.parse(JSON.stringify(teamWager));
              let newWagerList;
              let newWagerList1;
              let newWagerList2;

              switch (fix) {
                case 2:
                  newWagerList1 = new Array(newHeadList1.length).fill({
                    isNoData: true,
                  });
                  newWagerList2 = new Array(newHeadList2.length).fill({
                    isNoData: true,
                  });

                  newHeadList1.forEach((headData, headIndex) => {
                    const { WagerTypeIDs: headTypeList, WagerGrpIDs: headGroupList } = headData;
                    wagerList.forEach((wagerData) => {
                      if (
                        headTypeList.includes(wagerData.WagerTypeID) &&
                        headGroupList.includes(wagerData.WagerGrpID)
                      ) {
                        newWagerList1[headIndex] = wagerData;
                      }
                    });
                  });
                  newHeadList2.forEach((headData, headIndex) => {
                    const { WagerTypeIDs: headTypeList, WagerGrpIDs: headGroupList } = headData;
                    wagerList.forEach((wagerData) => {
                      if (
                        headTypeList.includes(wagerData.WagerTypeID) &&
                        headGroupList.includes(wagerData.WagerGrpID)
                      ) {
                        newWagerList2[headIndex] = wagerData;
                      }
                    });
                  });
                  newWagerList = [...newWagerList1, ...newWagerList2];
                  newTeamData.Wager = newWagerList;
                  newTeamData.Wager1 = newWagerList1;
                  newTeamData.Wager2 = newWagerList2;
                  break;
                case 3:
                  break;
                case 4:
                  newWagerList1 = new Array(newHeadList1.length).fill({
                    isNoData: true,
                  });
                  newWagerList2 = new Array(newHeadList2.length).fill({
                    isNoData: true,
                  });
                  newHeadList1.forEach((headData, headIndex) => {
                    const {
                      WagerTypeIDs: headTypeList,
                      WagerGrpIDs: headGroupList,
                      OULine: headLine,
                    } = headData;
                    const headGroup = headGroupList[0];
                    wagerList.forEach((wagerData) => {
                      if (headGroup === 128) {
                        if (headTypeList.includes(wagerData.WagerTypeID)) {
                          const { Odds: oddsList } = wagerData;
                          oddsList.forEach((oddsData) => {
                            if (oddsData.OULine === headLine) {
                              const newWagerData = JSON.parse(JSON.stringify(wagerData));
                              newWagerData.Odds = [oddsData];
                              newWagerList1[headIndex] = newWagerData;
                            }
                          });
                        }
                      } else {
                        if (
                          headTypeList.includes(wagerData.WagerTypeID) &&
                          headGroupList.includes(wagerData.WagerGrpID)
                        ) {
                          const { Odds: oddsList } = wagerData;
                          oddsList.forEach((oddsData) => {
                            if (oddsData.OULine === headLine) {
                              const newWagerData = JSON.parse(JSON.stringify(wagerData));
                              newWagerData.Odds = [oddsData];
                              newWagerList1[headIndex] = newWagerData;
                            }
                          });
                        }
                      }
                    });
                  });
                  newHeadList2.forEach((headData, headIndex) => {
                    const {
                      WagerTypeIDs: headTypeList,
                      WagerGrpIDs: headGroupList,
                      OULine: headLine,
                    } = headData;
                    const headGroup = headGroupList[0];
                    wagerList.forEach((wagerData) => {
                      if (headGroup === 128) {
                        if (headTypeList.includes(wagerData.WagerTypeID)) {
                          const { Odds: oddsList } = wagerData;
                          oddsList.forEach((oddsData) => {
                            if (oddsData.OULine === headLine) {
                              const newWagerData = JSON.parse(JSON.stringify(wagerData));
                              newWagerData.Odds = [oddsData];
                              newWagerList2[headIndex] = newWagerData;
                            }
                          });
                        }
                      } else {
                        if (
                          headTypeList.includes(wagerData.WagerTypeID) &&
                          headGroupList.includes(wagerData.WagerGrpID)
                        ) {
                          const { Odds: oddsList } = wagerData;
                          oddsList.forEach((oddsData) => {
                            if (oddsData.OULine === headLine) {
                              const newWagerData = JSON.parse(JSON.stringify(wagerData));
                              newWagerData.Odds = [oddsData];
                              newWagerList2[headIndex] = newWagerData;
                            }
                          });
                        }
                      }
                    });
                  });
                  newWagerList = [...newWagerList1, ...newWagerList2];
                  newTeamData.Wager = newWagerList;
                  newTeamData.Wager1 = newWagerList1;
                  newTeamData.Wager2 = newWagerList2;
                  break;
                case 5:
                  newWagerList = new Array(newHeadList.length).fill({
                    isNoData: true,
                  });
                  newHeadList.forEach((headData, headIndex) => {
                    const {
                      WagerTypeIDs: headTypeList,
                      WagerGrpIDs: headGroupList,
                      OULine: headLine,
                    } = headData;
                    const headGroup = headGroupList[0];
                    wagerList.forEach((wagerData) => {
                      if (headGroup === 128) {
                        if (headTypeList.includes(wagerData.WagerTypeID)) {
                          const { Odds: oddsList } = wagerData;
                          oddsList.forEach((oddsData) => {
                            if (oddsData.OULine === headLine) {
                              const newWagerData = JSON.parse(JSON.stringify(wagerData));
                              newWagerData.Odds = [oddsData];
                              newWagerList[headIndex] = newWagerData;
                            }
                          });
                        }
                      } else {
                        if (
                          headTypeList.includes(wagerData.WagerTypeID) &&
                          headGroupList.includes(wagerData.WagerGrpID)
                        ) {
                          const { Odds: oddsList } = wagerData;
                          oddsList.forEach((oddsData) => {
                            if (oddsData.OULine === headLine) {
                              const newWagerData = JSON.parse(JSON.stringify(wagerData));
                              newWagerData.Odds = [oddsData];
                              newWagerList[headIndex] = newWagerData;
                            }
                          });
                        }
                      }
                    });
                  });
                  newTeamData.Wager = newWagerList;
                  break;
                case 6:
                  newWagerList1 = new Array(newHeadList1.length).fill({
                    isNoData: true,
                  });
                  newWagerList2 = new Array(newHeadList2.length).fill({
                    isNoData: true,
                  });

                  newHeadList1.forEach((headData, headIndex) => {
                    const { WagerTypeIDs: headTypeList, WagerGrpIDs: headGroupList } = headData;
                    wagerList.forEach((wagerData) => {
                      if (
                        headTypeList.includes(wagerData.WagerTypeID) &&
                        headGroupList.includes(wagerData.WagerGrpID)
                      ) {
                        newWagerList1[headIndex] = wagerData;
                        wagerData.Odds.sort((odds1, odds2) => {
                          return odds1.HdpPos - odds2.HdpPos;
                        });
                      }
                    });
                  });
                  newHeadList2.forEach((headData, headIndex) => {
                    const { WagerTypeIDs: headTypeList, WagerGrpIDs: headGroupList } = headData;
                    wagerList.forEach((wagerData) => {
                      if (
                        headTypeList.includes(wagerData.WagerTypeID) &&
                        headGroupList.includes(wagerData.WagerGrpID)
                      ) {
                        newWagerList2[headIndex] = wagerData;
                        wagerData.Odds.sort((odds1, odds2) => {
                          return odds1.HdpPos - odds2.HdpPos;
                        });
                      }
                    });
                  });
                  newWagerList = [...newWagerList1, ...newWagerList2];
                  newTeamData.Wager = newWagerList;
                  newTeamData.Wager1 = newWagerList1;
                  newTeamData.Wager2 = newWagerList2;
                  break;
                default:
                  newWagerList = new Array(newHeadList.length).fill({
                    isNoData: true,
                  });
                  newHeadList.forEach((headData, headIndex) => {
                    const { WagerTypeIDs: headTypeList, WagerGrpIDs: headGroupList } = headData;
                    const headGroup = headGroupList[0];
                    wagerList.forEach((wagerData) => {
                      if (headGroup === 128) {
                        if (headTypeList.includes(wagerData.WagerTypeID)) {
                          newWagerList[headIndex] = wagerData;
                        }
                      } else {
                        if (
                          headTypeList.includes(wagerData.WagerTypeID) &&
                          headGroupList.includes(wagerData.WagerGrpID)
                        ) {
                          newWagerList[headIndex] = wagerData;
                        }
                      }
                    });
                  });
                  newTeamData.Wager = newWagerList;
              }

              const newDrewOdds = [];
              teamWager.forEach((wagerData) => {
                const { Odds: oddsList } = wagerData;
                const odds = oddsList[0];
                newDrewOdds.push(odds.DrewOdds);
              });
              newTeamData.hasDrewOdds = newDrewOdds.some((oddsData) => oddsData !== undefined);

              if (teamData.MoreCount !== -1) {
                hasMoreCount = true;
              }

              temTeamList.push(teamData.EvtID);

              return newTeamData;
            });

            newLeagueData.Team = newTeamList;

            return newLeagueData;
          });

        return {
          ...gameData,
          Items: {
            BestHead: [newHeadList, newHeadList1, newHeadList2],
            List: newLeagueList,
            hasMoreCount,
          },
        };
      });

      state.GameList = newGameList;
      if (isFavorite) {
        rootStore.dispatch('Setting/setFavorites', temTeamList);
      } else if (state.searchMode) {
        state.searchEvents = temTeamList;
      }
    },
    updateGameList(state, { updateData, isUpdateFromOtherStore = false }) {
      const { GameList } = state;
      if (GameList.length === 0) return;

      const notFindData = [];

      updateData.forEach((updateData) => {
        let isFind = false;

        GameList.forEach((GameData) => {
          const gameListIndex = GameData.Items.List.findIndex(
            (LeagueData) => LeagueData.LeagueID === updateData.LeagueID
          );

          if (gameListIndex !== -1) {
            try {
              GameData.Items.List[gameListIndex].Team.forEach((teamData, teamIndex) => {
                return teamData.Wager.forEach((wagerData) => {
                  if (wagerData?.isNoData) {
                  } else {
                    wagerData.Odds.forEach((oddData) => {
                      if (oddData.GameID === updateData.GameID) {
                        isFind = true;
                        oddData.HdpPos = updateData.HdpPos;
                        oddData.HomeHdp = updateData.HomeHdp;
                        oddData.AwayHdp = updateData.AwayHdp;
                        oddData.HomeHdpOdds = updateData.HomeHdpOdds;
                        oddData.AwayHdpOdds = updateData.AwayHdpOdds;
                        oddData.OULine = updateData.OULine;
                        oddData.OverOdds = updateData.OverOdds;
                        oddData.UnderOdds = updateData.UnderOdds;
                        oddData.HomeOdds = updateData.HomeOdds;
                        oddData.AwayOdds = updateData.AwayOdds;
                        oddData.DrewOdds = updateData.DrewOdds;
                        oddData.Status = updateData.Status;
                        teamData.EvtStatus = updateData.EvtStatus;
                      }
                    });
                  }
                });
              });

              // 只有移除 所有 team EvtStatus 都不為 1 時的 league
              // 檢查League Data 的Team 是否EvtStatus 都為1,如果都是非1的話 就移除此League資料
              const EvtStatusEnableList = GameData.Items.List[gameListIndex].Team.filter(
                (it) => it.EvtStatus === 1
              );
              if (EvtStatusEnableList.length === 0) {
                GameData.Items.List.splice(gameListIndex, 1);
              }
            } catch (err) {
              console.error('err:', err);
            }
          } else {
          }
        });

        if (!isFind && updateData.Status === 1) {
          notFindData.push(updateData);
        }
      });

      // 有主盤口沒有的遊戲資料時 更新主盤口
      // 自己store呼叫的 dispatch
      if (!isUpdateFromOtherStore) {
        // 更新數據有,但是Table卻沒有時,要重新打detail API
        if (notFindData.length !== 0) {
          state.isCallGameDetailAPI = !state.isCallGameDetailAPI;
        }
        rootStore.commit('MoreGame/updateMoreGameData', {
          isUpdateFromOtherStore: true,
          updateData,
        });
      }
    },
    updateTeamData(state, { updateData }) {
      if (state.GameList.length !== 0) {
        updateData.forEach((updateData) => {
          state.GameList.every((GameData) => {
            const gameListIndex = GameData.Items.List.findIndex(
              (LeagueData) => LeagueData.LeagueID === updateData.LeagueID
            );

            if (gameListIndex !== -1) {
              try {
                GameData.Items.List[gameListIndex].Team.every((teamData, teamIndex) => {
                  if (
                    teamData.AwayID === updateData.AwayID &&
                    teamData.HomeID === updateData.HomeID
                  ) {
                    teamData = { ...teamData, ...updateData };
                    GameData.Items.List[gameListIndex].Team[teamIndex] = teamData;
                    return false;
                  } else {
                    return true;
                  }
                });
              } catch (err) {
                console.error('updateTeamData:', err);
              }
              return false;
            } else {
              return true;
            }
          });
        });
      }
    },
    updateMoreCount(state, { updateData }) {
      if (state.GameList.length !== 0) {
        updateData.forEach((updateData) => {
          state.GameList.every((GameData) => {
            return GameData.Items.List.every((LeagueData) => {
              return LeagueData.Team.every((teamData) => {
                if (teamData.EvtID === updateData.EvtID) {
                  teamData.MoreCount = updateData.Count;
                  return false;
                } else {
                  return true;
                }
              });
            });
          });
        });
      }
    },
    setDetailTimeoutID(state, ID) {
      state.detailTimeoutID = ID;
    },
    readAnnouncement(state, announcement) {
      if (state.readAnnouncements === null) {
        let Announcements = [];
        const data = localStorage.getItem('Announcements');
        if (data !== null) {
          Announcements = JSON.parse(data);
        }
        state.readAnnouncements = Announcements;
      }
      if (!announcement.read) {
        announcement.read = true;
        state.readAnnouncements.push(announcement.Id);
        if (state.readAnnouncements.length > 100) {
          state.readAnnouncements.sort((a, b) => b - a);
          state.readAnnouncements.length = 100;
        }
        localStorage.setItem('Announcements', JSON.stringify(state.readAnnouncements));
      }
    },
    showLives(state) {
      state.livesShow = true;
    },
    hideLives(state) {
      state.livesShow = false;
    },
  },
  actions: {
    updateGameTypeList({ rootState, commit }) {
      const { GameTypeMenu } = rootState.siteInfo;
      if (GameTypeMenu) {
        commit('setGameTypeList', GameTypeMenu);
      }
      /*
      return apiGetGameType()
        .then((response) => {
          const { data } = response;
          if (!data || data.length === 0) {
          } else {
            commit('setGameTypeList', data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
        */
    },
    updateGameCatList({ state, commit }) {
      return apiGetGameCat(i18n.locale).then((res) => {
        /* res.push({
          CatID: GAME_FAVORITE_CAT,
          GroupCatIDs: [GAME_FAVORITE_CAT],
          GameScoreRefresh: false,
          EvtItem: [],
        }); */
        /*  res.push({
          CatID: GAME_HOT_CAT,
          GroupCatIDs: [GAME_HOT_CAT],
          GameScoreRefresh: false,
          EvtItem: [],
        }); */

        res.forEach((catData) => {
          const { CatID } = catData;
          catData.icon = getMenuIconByCatID(CatID);
          catData.color = getColorByCatId(CatID);
          catData.background = getBoardImageByCatId(CatID);
        });

        const catMap = res.reduce((map, catData) => {
          catData.GroupCatIDs.forEach((id) => {
            map = {
              ...map,
              ...{
                [id]: catData,
              },
            };
          });
          return map;
        }, {});

        commit('setCatList', res);
        commit('setCatMapData', catMap);

        state.teamImages.length = 0;
        // eslint-disable-next-line no-unused-expressions
        res?.forEach((item) => {
          if ([1, 31, 86, 101, 102].includes(item.CatID)) {
            state.teamImages.push(...item.GroupCatIDs);
          }
        });
      });
    },
    updateGameMenuList({ state, commit, dispatch }, clear = false) {
      if (clear) {
        commit('setFullMenuList', []);
      }
      return apiGetGameMenu()
        .then((response) => {
          const { data } = response;
          const { list, Default, OddsAdj } = data;
          state.Default = Default;

          // 冠軍放最前面
          list.forEach((item) => {
            // eslint-disable-next-line no-unused-expressions
            item?.LeftMenu?.item?.sort((a, b) => {
              if (a.catid === GAME_CHAMPION_CAT) return -1;
              else if (b.catid === GAME_CHAMPION_CAT) return 1;
              else return 0;
            });
          });

          commit('setFullMenuList', list);

          const { selectGameType, selectCatID } = state;
          if (selectGameType === null && !selectCatID) {
            const { GameType, catid, WagerTypeKey } = Default;
            commit('setCatIDAndGameTypeAndWagerType', {
              selectGameType: GameType,
              selectCatID: catid,
              selectWagerTypeKey: WagerTypeKey,
            });
          } else {
            if (selectGameType && selectCatID && selectCatID !== 999999) {
              const item = list.find((item) => item.GameType === selectGameType);
              if (item) {
                const item2 = item.LeftMenu?.item.find((item) => item.catid === selectCatID);
                if (!item2) {
                  commit('clearGameList');
                }
              }
            }
          }

          const OddsAdjMap = OddsAdj.reduce(
            (map, item) => ({ ...map, [item.CatID]: item.ParlayAdj }),
            {}
          );
          commit('setOddsAdj', OddsAdjMap);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateDefaultGameDetail({ state, commit, dispatch }) {
      if (!state.Default) return;
      const { GameType, catid, WagerTypeKey } = state.Default;
      commit('setCatIDAndGameTypeAndWagerType', {
        selectGameType: GameType,
        selectCatID: catid,
        selectWagerTypeKey: WagerTypeKey,
      });
      dispatch('updateGameDetail');
    },
    updateGameDetail({ rootState, state, commit, dispatch }, { clear = false } = {}) {
      const { selectGameType, selectCatID, selectWagerTypeKey, CatMapData, detailTimeoutID } =
        state;
      if (state.searchMode) return;

      if (detailTimeoutID) {
        clearTimeout(detailTimeoutID);
        commit('setDetailTimeoutID', null);
      }
      if (selectCatID === GAME_FAVORITE_CAT) {
        const EvtIDs = rootStore.state.Setting.UserSetting.favorites.join(',');
        const postData = {
          FavoritesModel: true,
          // GameType: selectGameType,
          EvtIDs,
        };
        if (clear) {
          commit('clearGameList');
        }
        return apiGetDetail(postData).then((response) => {
          const { data } = response;
          if (state.first && !data.length) {
            dispatch('nextFirstCat');
          } else {
            state.first = false;
            commit('setGameList', {
              data,
              isFavorite: true,
              postData,
            });
            const timeoutID = setTimeout(() => {
              if (sessionStorage.getItem('Token')) {
                commit('setDetailTimeoutID', null);
                dispatch('updateGameDetail', { clear: false });
              }
            }, 300000);
            commit('setDetailTimeoutID', timeoutID);

            // will - socket
            triggerSocket();
          }
        });
      } else if (selectCatID === GAME_HOT_CAT) {
        const postData = {
          GameType: selectGameType,
          CatID: selectCatID,
          WagerTypeKey: selectWagerTypeKey,
        };
        if (!postData.WagerTypeKey) {
          delete postData.WagerTypeKey;
        }
        if (clear) {
          commit('clearGameList');
        }
        return apiGetDetail(postData).then((response) => {
          const { data } = response;
          if (state.first && !data.length) {
            dispatch('nextFirstCat');
          } else {
            state.first = false;
            data.forEach((item) => {
              item.CatName = item.CatName.split(' ')[0];
            });
            commit('setGameList', {
              data,
              isFavorite: false,
              postData,
            });
            const timeoutID = setTimeout(
              () => {
                if (sessionStorage.getItem('Token')) {
                  commit('setDetailTimeoutID', null);
                  dispatch('updateGameDetail', { clear: false });
                }
              },
              data.length ? 300000 : 30000
            );
            commit('setDetailTimeoutID', timeoutID);

            // will - socket
            triggerSocket();
          }
        });
      } else {
        const show = rootState.Setting.UserSetting.tableSort;
        const postData = {
          GameType: selectGameType,
          CatID: selectCatID,
          WagerTypeKey: selectWagerTypeKey,
        };
        const payload = {
          ...postData,
          show,
        };
        if (!payload.WagerTypeKey) {
          delete payload.WagerTypeKey;
        }
        if (clear) {
          commit('clearGameList');
        }
        return apiGetDetail(payload).then((response) => {
          const { data } = response;
          if (state.first && !data.List.length) {
            dispatch('nextFirstCat');
          } else {
            state.first = false;
            const newData = [
              {
                CatID: selectCatID,
                CatName: CatMapData[selectCatID] ? CatMapData[selectCatID].Name : '',
                Items: data,
              },
            ];
            commit('setGameList', {
              data: newData,
              isFavorite: false,
              postData,
            });
            commit('updateSelectedGameList');

            const timeoutID = setTimeout(() => {
              if (sessionStorage.getItem('Token')) {
                commit('setDetailTimeoutID', null);
                dispatch('updateGameDetail', { clear: false });
              }
            }, 300000);
            commit('setDetailTimeoutID', timeoutID);

            // will - socket
            triggerSocket();
          }
        });
      }
    },
    updateGameDetailSmall({ rootState, state, commit }) {
      // will - socket
      if (isSocketOk()) return;

      const { selectGameType, selectCatID, selectWagerTypeKey } = state;
      if (state.searchMode) {
        const EvtIDs = state.searchEvents.join(',');
        return apiGetDetailSmall({
          FavoritesModel: true,
          // GameType: selectGameType,
          EvtIDs,
        }).then((response) => {
          const { data } = response;
          const { List, GameScoreHead, MoreCoutToEvtID } = data;
          if (List?.length) {
            commit('updateGameList', {
              updateData: List,
            });
          }
          if (GameScoreHead?.length) {
            commit('updateTeamData', {
              updateData: GameScoreHead,
            });
          }
          if (MoreCoutToEvtID?.length) {
            commit('updateMoreCount', {
              updateData: MoreCoutToEvtID,
            });
          }
        });
      } else if (selectCatID === GAME_FAVORITE_CAT) {
        const EvtIDs = rootState.Setting.UserSetting.favorites.join(',');
        return apiGetDetailSmall({
          FavoritesModel: true,
          // GameType: selectGameType,
          EvtIDs,
        }).then((response) => {
          const { data } = response;
          const { List, GameScoreHead, MoreCoutToEvtID } = data;
          if (List?.length) {
            commit('updateGameList', {
              updateData: List,
            });
          }
          if (GameScoreHead?.length) {
            commit('updateTeamData', {
              updateData: GameScoreHead,
            });
          }
          if (MoreCoutToEvtID?.length) {
            commit('updateMoreCount', {
              updateData: MoreCoutToEvtID,
            });
          }
        });
      } else {
        const postData = {
          GameType: selectGameType,
          CatID: selectCatID,
          WagerTypeKey: selectWagerTypeKey,
        };
        const payload = {
          ...postData,
        };
        if (!payload.WagerTypeKey) {
          delete payload.WagerTypeKey;
        }
        return apiGetDetailSmall(payload).then((response) => {
          const { data } = response;

          const { List, GameScoreHead, MoreCoutToEvtID } = data;
          if (List?.length) {
            commit('updateGameList', {
              updateData: List,
            });
          }
          if (GameScoreHead?.length) {
            commit('updateTeamData', {
              updateData: GameScoreHead,
            });
          }
          if (MoreCoutToEvtID?.length) {
            commit('updateMoreCount', {
              updateData: MoreCoutToEvtID,
            });
          }
        });
      }
    },
    updateGameSearch({ state, commit, dispatch }, keyword) {
      state.searchKey = keyword;
      state.searchMode = true;
      return apiGetSearch(keyword).then((response) => {
        const { data } = response;
        commit('setGameList', {
          data,
          isFavorite: false,
          postData: {
            keyword,
          },
        });
        if (state.timeoutID) {
          clearTimeout(state.timeoutID);
          commit('setDetailTimeoutID', null);
        }
      });
    },
    nextFirstCat({ state, commit, dispatch }) {
      const { FullMenuList } = state;
      const typeItem = FullMenuList.find(({ GameType }) => GameType === 1);
      if (typeItem) {
        if (state.selectGameType === 1) {
          state.firstCats.push(state.selectCatID);
        }
        const catItem = typeItem.LeftMenu.item.find(({ catid }) => !state.firstCats.find(catid));
        let WagerTypeKey = null;
        if (catItem.Items) {
          WagerTypeKey = catItem.Items[0].WagerTypeKey;
        }
        commit('setCatIDAndGameTypeAndWagerType', {
          selectGameType: 1,
          selectCatID: catItem.catid,
          selectWagerTypeKey: WagerTypeKey,
        });
        dispatch('updateGameDetail');
      } else {
        state.first = false;
        dispatch('updateGameDetail');
      }
    },
    GetAnnouncement(store) {
      return new Promise((resolve, reject) => {
        return apiGetAnnouncement({
          sla: true,
        }).then((res) => {
          if (store.state.readAnnouncements === null) {
            let Announcements = [];
            const data = localStorage.getItem('Announcements');
            if (data !== null) {
              Announcements = JSON.parse(data);
            }
            store.state.readAnnouncements = Announcements;
          }
          const announcements = store.state.readAnnouncements;
          const announcements_data = res.data;
          for (const announcement of announcements_data) {
            if (announcements.includes(announcement.Id)) {
              announcement.read = true;
            } else {
              announcement.read = false;
            }
          }
          store.state.announcements = announcements_data;
          resolve(res);
        });
      });
    },
    GetSportNews(store) {
      return new Promise((resolve, reject) => {
        return apiGetNews().then((res) => {
          resolve(res);
        });
      });
    },
    GetQAHistory(store, { isGuestMode = false }) {
      return new Promise((resolve, reject) => {
        return apiGetCServiceMessages(isGuestMode).then((res) => {
          resolve(res);
        });
      });
    },
    GetCountMes(store, { isGuestMode = false }) {
      return new Promise((resolve, reject) => {
        return apiGetCServiceUnread(isGuestMode).then((res) => {
          resolve(res);
        });
      });
    },
    SendReadMes(store, { isGuestMode = false }) {
      return new Promise((resolve, reject) => {
        return apiCServicePostRead(isGuestMode).then((res) => {
          resolve(res);
        });
      });
    },
    SendQAMessage(store, { message, isGuestMode = false }) {
      return new Promise((resolve, reject) => {
        const postData = {
          Content: message,
        };
        return apiPostCServiceMessage(postData, isGuestMode).then((res) => {
          resolve(res);
        });
      });
    },
    GetQAFirstMes(store, { isGuestMode = false }) {
      return new Promise((resolve, reject) => {
        return apiGetCServiceWelcome(isGuestMode).then((res) => {
          resolve(res);
        });
      });
    },
    SendQAFile(store, { base64File, name, isGuestMode = false }) {
      return new Promise((resolve, reject) => {
        const postData = {
          Content: base64File,
          FileName: name,
        };
        return apiPostCServiceFile(postData, isGuestMode).then((res) => {
          resolve(res);
        });
      });
    },
    GetGameResult(store, { CatID, ScheduleTime, LeagueIDs, EvtID, ItemKey }) {
      return new Promise((resolve, reject) => {
        let postData = {};
        if (EvtID) {
          postData = { EvtID };
        } else {
          postData = {
            CatID,
            LeagueIDs,
            ScheduleTime,
            ItemKey,
          };
        }
        return apiGetGameResult(postData)
          .then(async (res) => {
            resolve(res);
          })
          .catch(reject);
      });
    },
    GetGameResultLeagues(store, postData = {}) {
      return new Promise((resolve, reject) => {
        return apiGetGameLeague(postData)
          .then(async (res) => resolve(res))
          .catch(reject);
      });
    },
    GetLiveURL(store) {
      return new Promise((resolve, reject) => {
        return apiGetLive().then((res) => {
          resolve(res);
        });
      });
    },
    GetMovieURL(store) {
      return new Promise((resolve, reject) => {
        return apiGetMovie().then((res) => {
          resolve(res);
        });
      });
    },
    async initGame({ rootState, state, commit, dispatch }) {
      if (window.chat) {
        window.chat.reset();
      }
      if (window.OddData?.clear) {
        window.OddData.clear();
      }
      state.first = true;
      state.firstCats = [];
      commit('MoreGame/hideStandaloneLive', null, { root: true });
      await dispatch('Setting/init', null, { root: true });
      dispatch('Setting/clearLeagues', null, { root: true });
      commit('BetCart/clearCart', null, { root: true });
      commit('BetCart/clearLastFirstBetData', null, { root: true });
      commit('setQuickBetEnable', false);
      commit('MoreGame/setIsShowMoreGame', null, { root: true });

      return Promise.all([
        dispatch('updateGameCatList'),
        dispatch('updateGameTypeList'),
        dispatch('updateGameMenuList'),
        dispatch('updateUserBalance', null, { root: true }),
      ]).then(() => {
        const limits = localStorage.getItem('limits');
        const limits_key = localStorage.getItem('limits_key');
        if (!limits || limits_key !== rootState.sessionToken) {
          return dispatch('updateLimits');
        } else {
          state.limits = JSON.parse(limits);
          return true;
        }
      });
    },
    updateLimits({ rootState, state }) {
      return apiGetGameLimit().then((response) => {
        const { data } = response;
        localStorage.setItem('limits', JSON.stringify(data));
        localStorage.setItem('limits_key', rootState.sessionToken);
        state.limits = data;
      });
    },
    updateLives({ state }) {
      return apiGetLiveEvents()
        .then((response) => {
          const {
            data: { List: leagues },
          } = response;
          const lives = [];
          leagues.forEach((league) => {
            const { CatID, LeagueID, LeagueNameStr, Team: events } = league;
            const cat = state.CatMapData[CatID];
            events.forEach((event) => {
              const live = {
                ...event,
                CatID: cat.CatID,
                CatName: cat.Name,
                LeagueID,
                LeagueNameStr,
              };
              lives.push(live);
            });
          });
          state.lives = lives;
        })
        .catch(() => {
          state.lives.length = 0;
        });
    },
    getCatMenuKeys({ state }, { cat }) {
      const { FullMenuList } = state;
      const types = [2, 3];
      for (const type of types) {
        const typeItem = FullMenuList.find(({ GameType }) => GameType === type);
        if (typeItem) {
          const typeCatItems = typeItem.LeftMenu.item;
          const catItem = typeCatItems.find(({ catid }) => catid === cat);
          if (catItem) {
            const catWagerItems = catItem.Items;
            const wager = catWagerItems.length ? catWagerItems[0].WagerTypeKey : null;
            return { type, cat, wager };
          }
        }
      }
      return false;
    },
    showTable({ state, commit, dispatch }, { type, cat, wager = null }) {
      const { selectGameType, selectCatID, selectWagerTypeKey } = state;
      if (selectGameType === type && selectCatID === cat && selectWagerTypeKey === wager) return;
      window.OddData.clear();
      commit('setLoading', true, { root: true });
      commit('setCatIDAndGameTypeAndWagerType', {
        selectGameType: type,
        selectCatID: cat,
        selectWagerTypeKey: wager,
      });
      commit('MoreGame/closeMoreGameList', null, { root: true });
      dispatch('updateGameDetail', { clear: true }).finally(() => {
        commit('setLoading', false, { root: true });
      });
    },
    showPopularTable({ dispatch }) {
      dispatch('getCatMenuKeys', { cat: 888888 })
        .then((keys) => {
          if (keys) {
            dispatch('showTable', keys);
            return true;
          }
          return false;
        })
        .catch(() => {
          return false;
        });
    },
    showChampionTable({ dispatch }) {
      dispatch('getCatMenuKeys', { cat: 86 })
        .then((keys) => {
          if (keys) {
            dispatch('showTable', keys);
            return true;
          }
          return false;
        })
        .catch(() => {
          return false;
        });
    },
    openPromotionPage() {
      window.name = 'games';
      if (window.self !== window.top) {
        openNewWindow('promotion', 'promotion');
      } else {
        const { href: url } = router.resolve({
          name: 'promotion',
        });
        if (url) window.open(url, 'promotion');
      }
    },
  },
};
